import { createSlice } from '@reduxjs/toolkit';
import {
  addPartner,
  getPartner,
  updatePartner,
  deletePartner,
} from './partnerActions';

const initialState = {
  loading: false,
  error: null,
  success: null,
  partnersList: null,
};
const partnerSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {},
  extraReducers: {
    [getPartner.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.partnersList = null;
    },
    [getPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.partnersList = action.payload;
      state.success = null;
    },
    [getPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.partnersList = null;
    },
    [addPartner.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [addPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [addPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [updatePartner.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updatePartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updatePartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [deletePartner.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deletePartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deletePartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});
export default partnerSlice.reducer;
