import { createSlice } from "@reduxjs/toolkit";
import { createOffer, getOffers } from "./offerActions";
const initialState = {
  loading: false,
  error: null,
  offersList: null,
  success: null,
};

const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {},
  extraReducers: {
    [getOffers.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getOffers.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.offersList = action.payload;
      state.success = action.payload.message;
    },
    [getOffers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [createOffer.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [createOffer.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [createOffer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default offerSlice.reducer;
