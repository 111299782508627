import { createAsyncThunk } from "@reduxjs/toolkit";
import jitApi from "../../apis/jitApi";
export const addVechicle = createAsyncThunk(
  "addVechicle",
  async (formData, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.post(
        "/public/addvehicledetails",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getVechicleList = createAsyncThunk(
  "getVechicleList",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get("/public/fetchvehicledetails", config);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const vechicleMaintainance = createAsyncThunk(
  "vechicleMaintainance",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.post(
        "/public/vehiclemaintainanceadd",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const listVechicleMaintainance = createAsyncThunk(
  "listVechicleMaintainance",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get(
        `/public/fetchvehiclemaintainancehistory/${id}`,
        config
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateVechicle = createAsyncThunk(
  "updateVechicle",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.put(
        `/public/updatevehicledetails/${data.selectedId}`,
        data.formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteVehcilemaindata = createAsyncThunk(
  "deletevehcilemaintainance",
  async (selectedId, { getState, rejectWithValue }) => {
    console.log(selectedId);
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.delete(
        `/public/deletevehcilemaintainance/${selectedId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateMaintananceVehicle = createAsyncThunk(
  "updateMaintananceVehicle",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.put(
        `/public/updatevehiclemaintanence/${data.selectedId}`,
        data.formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteVehicle = createAsyncThunk(
  "deletevehicle",
  async (selectedId, { getState, rejectWithValue }) => {
    console.log(selectedId);
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.delete(
        `/public/deletevehicle/${selectedId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
