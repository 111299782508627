import { createSlice } from "@reduxjs/toolkit";
import {
  createCod,
  getAllCod,
  codList,
  updateCod,
  codEntry,
  listCodNo,
  deleteCod,
  shortcollectionenrty,
  listShortcollection,
} from "./codActions";
const initialState = {
  loading: false,
  error: null,
  codList: null,
  success: null,
  codEntriesList: null,
};
const codRiderSlice = createSlice({
  name: "codRider",
  initialState,
  reducers: {},
  extraReducers: {
    // cod no

    [listCodNo.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.codNoList = null;
    },
    [listCodNo.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.codNoList = action.payload;
      state.success = null;
    },
    [listCodNo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.codNoList = null;
    },
    // cod rider enty & list
    [createCod.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.codList = null;
      state.success = null;
    },
    [createCod.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
      state.codList = null;
    },
    [createCod.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.codList = null;
      state.success = null;
    },
    [getAllCod.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.codList = null;
      state.success = null;
    },
    [getAllCod.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.codList = action.payload;
      state.success = null;
    },
    [getAllCod.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.codList = null;
      state.success = null;
    },
    // cod entry & list
    [codEntry.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.codEntriesList = null;
      state.success = null;
    },
    [codEntry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
      state.codEntriesList = null;
    },
    [codEntry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.codEntriesList = null;
      state.success = null;
    },
    [codList.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.codEntriesList = null;
      state.success = null;
    },
    [codList.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.codEntriesList = action.payload;
      state.success = null;
    },
    [codList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.codEntriesList = null;
      state.success = null;
    },
    // cod rider entry update
    [updateCod.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateCod.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateCod.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    // cod rider entry update
    [deleteCod.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [deleteCod.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteCod.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
  //short collection
  // cod entry & list
  [shortcollectionenrty.pending]: (state, action) => {
    state.loading = true;
    state.error = null;
    state.employeeList = null;
    state.success = null;
  },
  [shortcollectionenrty.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = null;
    state.success = action.payload.message;
  },
  [shortcollectionenrty.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.message;
    state.employeeList = null;
    state.success = null;
  },
  [listShortcollection.pending]: (state, action) => {
    state.loading = true;
    state.error = null;
    state.success = null;
  },
  [listShortcollection.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = null;
    state.maintainanceList = action.payload;
    state.success = null;
  },
  [listShortcollection.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.message;
    state.success = null;
  },
});
export default codRiderSlice.reducer;
