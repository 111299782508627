import { lazy } from 'react';
const Dashboard = lazy(() => import('../pages/public/Dashboard'));
// admin
const Users = lazy(() => import('../pages/admin/Users'));
const Notifications = lazy(() =>
  import('../pages/admin/Notifications')
);
//users
const Employee = lazy(() => import('../pages/public/Employee'));
const Vechicle = lazy(() => import('../pages/public/Vechicle'));
const Partners = lazy(() => import('../pages/public/Partners'));
const ClientServices = lazy(() =>
  import('../pages/public/ClientServices')
);
const WorkAssign = lazy(() => import('../pages/public/WorkAssign'));
const CodEntry = lazy(() => import('../pages/public/CodEntry'));
const CodReport = lazy(() => import('../pages/public/CodReport'));
const Treasury = lazy(() => import('../pages/public/Treasury'));
const JournalEntry = lazy(() =>
  import('../pages/public/JournalEntry')
);
const CodRider = lazy(() => import('../pages/public/CodRider'));
export const adminRoutes = [
  {
    title: 'Dashboard',
    path: '/app',
    element: <Dashboard />,
  },
  {
    title: 'Notifications',
    path: '/app/notifications',
    element: <Notifications />,
  },

  {
    title: 'Employee ',
    path: '/app/employee',
    element: <Employee />,
  },
  {
    title: 'User',
    path: '/app/user',
    element: <Users />,
  },
  {
    title: 'vehicle',
    path: '/app/vechicle',
    element: <Vechicle />,
  },
  {
    title: 'clients',
    path: '/app/partners',
    element: <Partners />,
  },
  {
    title: 'clients services',
    path: '/app/client-services',
    element: <ClientServices />,
  },
  {
    title: 'work assign',
    path: '/app/workassign',
    element: <WorkAssign />,
  },
  {
    title: 'COD',
    path: '/app/cod',
    element: <CodEntry />,
  },
  {
    title: 'COD Rider',
    path: '/app/cod-rider',
    element: <CodRider />,
  },
  {
    title: 'COD Report',
    path: '/app/cod-report',
    element: <CodReport />,
  },
  {
    title: 'Treasury',
    path: '/app/treasury',
    element: <Treasury />,
  },
  {
    title: 'BANK JOURNAL ENTRY',
    path: '/app/bank-journal-entry',
    element: <JournalEntry />,
  },
];

export const empRoutes = [
  {
    title: 'dashboard',
    path: '/app',
    element: <Dashboard />,
  },

  {
    title: 'Employee ',
    path: '/app/employee',
    element: <Employee />,
  },
  {
    title: 'vehicle',
    path: '/app/vechicle',
    element: <Vechicle />,
  },
  {
    title: 'clients',
    path: '/app/partners',
    element: <Partners />,
  },
  {
    title: 'clients services',
    path: '/app/client-services',
    element: <ClientServices />,
  },
  {
    title: 'work assign',
    path: '/app/workassign',
    element: <WorkAssign />,
  },
  {
    title: 'COD',
    path: '/app/cod',
    element: <CodEntry />,
  },
  {
    title: 'COD Rider',
    path: '/app/cod-rider',
    element: <CodRider />,
  },
  {
    title: 'COD Report',
    path: '/app/cod-report',
    element: <CodReport />,
  },
  {
    title: 'Treasury',
    path: '/app/treasury',
    element: <Treasury />,
  },
  {
    title: 'BANK JOURNAL ENTRY',
    path: '/app/bank-journal-entry',
    element: <JournalEntry />,
  },
];
