import { createSlice } from '@reduxjs/toolkit';
import { userLogin, userRegister } from './userActions';

const initialState = {
  loading: false,
  userInfo: sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo'))
    : null,
  error: null,
  success: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      sessionStorage.removeItem('userInfo'); // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    // userLogin
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.success = payload.message;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.success = null;
    },
    [userRegister.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [userRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message;
      state.error = null;
    },
    [userRegister.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.success = null;
    },
  },
});
// export actions
export const { logout } = userSlice.actions;
export default userSlice.reducer;
