import { createSlice } from '@reduxjs/toolkit';
import {
  getVechicleList,
  addVechicle,
  listVechicleMaintainance,
  vechicleMaintainance,
  updateVechicle,
  deleteVehcilemaindata,
  deleteVehicle,
  updateMaintananceVehicle,
} from './vechicleActions';
const initialState = {
  loading: false,
  error: null,
  VechicleList: null,
  maintainanceList: null,
  success: null,
};
const vechicleSlice = createSlice({
  name: 'vechile',
  initialState,
  reducers: {},
  extraReducers: {
    [addVechicle.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [addVechicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [addVechicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [updateVechicle.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateVechicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateVechicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [getVechicleList.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [getVechicleList.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.VechicleList = action.payload;
      state.success = null;
    },
    [getVechicleList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [vechicleMaintainance.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [vechicleMaintainance.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [vechicleMaintainance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [listVechicleMaintainance.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [listVechicleMaintainance.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.maintainanceList = action.payload;
      state.success = null;
    },
    [listVechicleMaintainance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [deleteVehcilemaindata.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteVehcilemaindata.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteVehcilemaindata.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [deleteVehicle.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    //update vechile
    [updateMaintananceVehicle.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [updateMaintananceVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateMaintananceVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});

export default vechicleSlice.reducer;
