import { createSlice } from "@reduxjs/toolkit";
import {
  addUsers,
  employeeList,
  sendCredntial,
  deleteUser,
  updateUser,
} from "./usersActions";
const initialState = {
  loading: false,
  error: null,
  usersLists: null,
  success: null,
};
const usersSlice = createSlice({
  name: "list",
  initialState,
  reducers: {},
  extraReducers: {
    [addUsers.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.usersLists = null;
    },
    [addUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.usersLists = null;
      state.success = action.payload.message;
    },
    [addUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.usersLists = null;
    },
    [employeeList.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.usersLists = null;
      state.success = null;
    },
    [employeeList.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.usersLists = action.payload;
      state.success = null;
    },
    [employeeList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.usersLists = null;
    },
    [sendCredntial.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [sendCredntial.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [sendCredntial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [updateUser.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [deleteUser.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});
export default usersSlice.reducer;
