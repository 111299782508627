import { createSlice } from "@reduxjs/toolkit";
import {
  assignWork,
  getAssignedWork,
  updateAssignWork,
  deleteWorkAssign,
} from "./workActions";
const initialState = {
  loading: false,
  error: null,
  success: null,
  assignedWorkList: null,
};

const workSlice = createSlice({
  name: "workAssign",
  initialState,
  reducers: {},
  extraReducers: {
    [getAssignedWork.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.assignedWorkList = null;
    },
    [getAssignedWork.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.assignedWorkList = action.payload;
      state.success = null;
    },
    [getAssignedWork.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [assignWork.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [assignWork.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [assignWork.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [updateAssignWork.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateAssignWork.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateAssignWork.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [deleteWorkAssign.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteWorkAssign.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteWorkAssign.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
  },
});

export default workSlice.reducer;
