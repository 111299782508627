import { createSlice } from "@reduxjs/toolkit";
import {
  createNotification,
  getNotification,
  deleteNotification,
} from "./adminActions";
const initialState = {
  loading: false,
  error: null,
  notificationList: null,
  success: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [createNotification.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [createNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [createNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [getNotification.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.notificationList = action.payload;
    },
    [getNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [deleteNotification.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});
export default adminSlice.reducer;
