import { createSlice } from "@reduxjs/toolkit";
import { getReportList, getUsersReportList } from "./reportActions";

const initialState = {
  loading: false,
  error: null,
  reportList: null,
  usersReports: null,
  success: false,
};
const reportSlice = createSlice({
  name: "list",
  initialState,
  reducers: {},
  extraReducers: {
    [getReportList.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getReportList.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.reportList = action.payload;
    },
    [getReportList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getUsersReportList.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getUsersReportList.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.usersReports = action.payload;
    },
    [getUsersReportList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default reportSlice.reducer;
