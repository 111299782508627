import { createAsyncThunk } from "@reduxjs/toolkit";
import jitApi from "../../apis/jitApi";
export const genrateCodReport = createAsyncThunk(
  "cod/genrateCodReport",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.post(
        "/public/codereportgenerate",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const listCodReport = createAsyncThunk(
  "cod/listCodReport",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get("/public/fetchcodreportdata", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCodReport = createAsyncThunk(
  "deleteCodReport",
  async (selectedId, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.delete(
        `/public/deletecodreport/${selectedId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateCodReport = createAsyncThunk(
  "updateCodReport",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.put(
        `/public/updatecodreport/${data.selectedId}`,
        data.formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
