import { createAsyncThunk } from "@reduxjs/toolkit";
import jitApi from "../../apis/jitApi";

export const getPartner = createAsyncThunk(
  "partners/getPartners",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get("/public/fetchallpartner", config);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatePartner = createAsyncThunk(
  "partners/updatePartners",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.put(
        `/public/updateclientprofile/${data.selectedId}`,
        data.formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPartner = createAsyncThunk(
  "partners/addPartners",
  async (formData, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.post(
        "/public/createpartner",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "employee/deletePartner",
  async (selectedId, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.delete(
        `/public/deleteclient/${selectedId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
