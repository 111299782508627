import { createAsyncThunk } from "@reduxjs/toolkit";
import jitApi from "../../apis/jitApi";
import { toast } from "react-toastify";
export const addJournalEntry = createAsyncThunk(
  "journalEntry/addJournalEntry",
  async (formData, { getState, rejectWithValue, success }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.post(
        "/public/banktreasuryentry",
        formData,
        config
      );
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getJournalEntry = createAsyncThunk(
  "journalEntry/getJournalEntry",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get(
        "/public/fetchbankjournelentries",
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteBankJournalEntry = createAsyncThunk(
  "employee/deletebankjournalentry",
  async (bankjournalentryid, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.delete(
        `/public/deleteBankJournalEntry/${bankjournalentryid}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const UpdateBankJournalEntry = createAsyncThunk(
  "employee/updateBankJournalEntry",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.put(
        `public/updatebankjournalentry/${data.selectedId}`,
        data.formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
