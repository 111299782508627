import { createAsyncThunk } from "@reduxjs/toolkit";
import jitApi from "../../apis/jitApi";
export const getClientServices = createAsyncThunk(
  "getClientServices",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get(
        `/public/clientemployeeidentries/${id}`,
        config
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const cretateClientServices = createAsyncThunk(
  "createClientServices",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.post(
        `/public/clientemployeeidentry`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateClientServices = createAsyncThunk(
  "updateClientServices",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.put(
        `/public/updateclientemployeeidentries/${data.selectedId}`,
        data.formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteClientEmployee = createAsyncThunk(
  "deleteClientServices",
  async (selectedId, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.delete(
        `/public/deleteclientemployeeid/${selectedId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
