import { createSlice } from "@reduxjs/toolkit";
import {
  cretateClientServices,
  getClientServices,
  updateClientServices,
  deleteClientEmployee,
} from "./servicesActions";
const initialState = {
  loading: false,
  error: null,
  clientServicesList: null,
  success: null,
};
const clientServicesSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [cretateClientServices.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [cretateClientServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [cretateClientServices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [updateClientServices.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateClientServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateClientServices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [getClientServices.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [getClientServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.clientServicesList = action.payload;
    },
    [getClientServices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [deleteClientEmployee.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteClientEmployee.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteClientEmployee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});
export default clientServicesSlice.reducer;
