import { createSlice } from "@reduxjs/toolkit";
import {
  UpdateEmployee,
  addEmployee,
  deleteRider,
  getEmployeeList,
} from "./employeeActions";
const initialState = {
  loading: false,
  error: null,
  employeeList: null,
  success: null,
};
const employeeSlice = createSlice({
  name: "Employee",
  initialState,
  reducers: {},
  extraReducers: {
    [addEmployee.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.employeeList = null;
      state.success = null;
    },
    [addEmployee.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [addEmployee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.employeeList = null;
      state.success = null;
    },
    [UpdateEmployee.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [UpdateEmployee.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [UpdateEmployee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.success = null;
    },
    [getEmployeeList.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.employeeList = null;
      state.success = null;
    },
    [getEmployeeList.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.employeeList = action.payload.data;
      state.success = null;
    },
    [getEmployeeList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.employeeList = null;
      state.success = null;
    },
    [deleteRider.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteRider.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteRider.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});

export default employeeSlice.reducer;
