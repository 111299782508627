import React, { useEffect, lazy } from "react";
import { v4 as uuidv } from "uuid";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// routes
import ProtectedRoute from "./routes/ProtectedRoute";
import { adminRoutes, empRoutes } from "./routes/config";
// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// layouts
const DashboardLayout = lazy(() =>
  import("./layouts/dashboard/DashboardLayout")
);
const HomeLayout = lazy(() => import("./layouts/Home/HomeLayout"));
// pages
const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const { userInfo } = useSelector((state) => state.user);
  const routes =
    userInfo !== null && userInfo?.role === "Admin" ? adminRoutes : empRoutes;

  return (
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route
          path="/app"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          {routes?.map((route) => (
            <Route
              key={uuidv()}
              path={route.path}
              element={<ProtectedRoute>{route.element}</ProtectedRoute>}
            />
          ))}
        </Route>
        <Route path="*" element={<Navigate to="/app" />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.StrictMode>
  );
};

export default React.memo(App);
