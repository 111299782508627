import { createAsyncThunk } from "@reduxjs/toolkit";
import jitApi from "../../apis/jitApi";
export const addTreasury = createAsyncThunk(
  "treasury/addTreasury",
  async (formData, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.post(
        "/public/treasurydataentry",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTreasuryList = createAsyncThunk(
  "treasury/treasuryList",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get("/public/fetchalltreasury", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SearchCodByDate = createAsyncThunk(
  "treasury/SearchCodByDate",
  async (searchText, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.get(
        `/public/treasuryentry/${searchText}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTreasury = createAsyncThunk(
  "treasury/delete",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.delete(
        `/public/deletetreauryonedata/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateTreasury = createAsyncThunk(
  "treasury/update",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().user;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await jitApi.put(
        `/public/updatetreasury/${data.selectedIdforupdate}`,
        data.formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
