import { createSlice } from "@reduxjs/toolkit";
import {
  addTreasury,
  SearchCodByDate,
  getTreasuryList,
  deleteTreasury,
  updateTreasury,
} from "./treasuryActions";
const initialState = {
  loading: false,
  error: null,
  treasuryList: null,
  searchCod: null,
  success: null,
};
const treasurySlice = createSlice({
  name: "Treasury",
  initialState,
  reducers: {},
  extraReducers: {
    [addTreasury.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.treasuryList = null;
      state.success = null;
    },
    [addTreasury.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.treasuryList = null;
      state.success = action.payload.message;
    },
    [addTreasury.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.treasuryList = null;
      state.success = null;
    },
    [getTreasuryList.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.treasuryList = null;
      state.success = null;
    },
    [getTreasuryList.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.treasuryList = action.payload;
      state.success = null;
    },
    [getTreasuryList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.treasuryList = null;
      state.success = null;
    },
    [SearchCodByDate.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.searchCod = null;
      state.success = null;
    },
    [SearchCodByDate.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.searchCod = action.payload;
      state.success = null;
    },
    [SearchCodByDate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.searchCod = null;
      state.success = null;
    },
    [deleteTreasury.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteTreasury.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteTreasury.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [updateTreasury.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateTreasury.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateTreasury.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});

export default treasurySlice.reducer;
