import { createSlice } from "@reduxjs/toolkit";
import {
  genrateCodReport,
  listCodReport,
  deleteCodReport,
  updateCodReport,
} from "./codReportActions";
const initialState = {
  loading: false,
  error: null,
  codReportList: null,
  success: null,
};
const codReportSlice = createSlice({
  name: "cod",
  initialState,
  reducers: {},
  extraReducers: {
    [genrateCodReport.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.codReportList = null;
    },
    [genrateCodReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
      state.codReportList = null;
    },
    [genrateCodReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.codReportList = null;
    },
    [listCodReport.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.codReportList = null;
    },
    [listCodReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.codReportList = action.payload;
      state.success = null;
    },
    [listCodReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.codReportList = null;
    },
    [deleteCodReport.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteCodReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteCodReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [updateCodReport.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateCodReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateCodReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});

export default codReportSlice.reducer;
