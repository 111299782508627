import { createSlice } from "@reduxjs/toolkit";
import { createCodNo, listCodNo, updateCod,deleteCod } from "./codActions";
const initialState = {
  loading: false,
  error: null,
  codNoList: null,
  success: null,
};
const codSlice = createSlice({
  name: "cod",
  initialState,
  reducers: {},
  extraReducers: {
    // cod no
    [createCodNo.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.codNoList = null;
    },
    [createCodNo.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
      state.codNoList = null;
    },
    [createCodNo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.codNoList = null;
    },
    [listCodNo.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      state.codNoList = null;
    },
    [listCodNo.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.codNoList = action.payload;
      state.success = null;
    },
    [listCodNo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
      state.codNoList = null;
    },

    // cod rider entry update
    [updateCod.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [updateCod.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [updateCod.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [deleteCod.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [deleteCod.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteCod.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});
export default codSlice.reducer;
