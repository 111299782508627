import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./authFeatures/userSlice";
import vechicleReducer from "./vechicleFeatures/vechicleSlice";
import usersReducer from "./usersFeatures/usersSlice";
import reportReducer from "./reportFeatures/reportSlice";
import employeeReducer from "./employeeFeatures/employeeSlice";
import adminReducer from "./adminFeatures/adminSlice";
import offerReducer from "./offerFeatures/offerSlice";
import partnerReducer from "./partnerFeatures/partnerSlice";
import clientServicesReducer from "./clientServicesFeatures/servicesSlice";
import workReducer from "./workFeatures/workSlice";
import codReducer from "./codFeatures/codSlice";
import codRiderReducer from "./codRiderFeatures/codSlice"
import codReportReducer from "./codReportFeatures/codReportSlice";
import jeReducer from "./journalEntryFeatures/jeSlice";
import treasuryReducer from "./treasuryFeatures/treasurySlice";
const store = configureStore({
  reducer: {
    user: userReducer,
    vechicle: vechicleReducer,
    usersList: usersReducer,
    reports: reportReducer,
    employee: employeeReducer,
    adminLists: adminReducer,
    offers: offerReducer,
    partners: partnerReducer,
    assignedwork: workReducer,
    cod: codReducer,
    codRider:codRiderReducer,
    codReport: codReportReducer,
    clientServices: clientServicesReducer,
    journalEntry: jeReducer,
    treasury: treasuryReducer,
  },
});
export default store;
