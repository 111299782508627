import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
// theme
import Loading from "./layouts/Loading";
import ThemeCustomization from "./theme";

//store
import { Provider } from "react-redux";
import store from "./store";

//***************************************************************//
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeCustomization>
        <Suspense fallback={<Loading />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </ThemeCustomization>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorker.unregister();

reportWebVitals();
