import { createSlice } from "@reduxjs/toolkit";
import {
  addJournalEntry,
  getJournalEntry,
  deleteBankJournalEntry,
  UpdateBankJournalEntry,
} from "./jeActions";
const initialState = {
  loading: false,
  error: null,
  success: null,
  journalEntriesList: null,
};
const jeSlice = createSlice({
  name: "journalEntry",
  initialState,
  reducers: {},
  extraReducers: {
    [addJournalEntry.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [addJournalEntry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [addJournalEntry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getJournalEntry.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getJournalEntry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.journalEntriesList = action.payload;
    },
    [getJournalEntry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteBankJournalEntry.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [deleteBankJournalEntry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [deleteBankJournalEntry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [UpdateBankJournalEntry.pending]: (state, action) => {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    [UpdateBankJournalEntry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload.message;
    },
    [UpdateBankJournalEntry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});
export default jeSlice.reducer;
